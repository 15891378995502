<template>
  <div>
    <section class="welcome">
    </section>
    <div class="section container">
      <div class="welcome__inner confirmed__pay">
        <div class="form__container">
          <h3>404 / Страница не найдена...</h3>
          <a href="/" class="confirmed__pay__link">Вернуться на главную</a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

export default {
  async asyncData ({ store }) {
    await store.dispatch('GET_HOME_PAGE')
  },
  name: 'NotFound',
  metaInfo () {
    return this.$seo(
      'common',
      'Mavadda',
      'Mavadda',
      'Mavadda',
      'Mavadda',
      '',
      'Mavadda'
    )
  },
  jsonld () {
    return {
      '@context': 'https://schema.org',
      '@type': 'Organization'
    }
  }
}
</script>
